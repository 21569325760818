import { create } from 'zustand';
import { getClosestIngredients, getProductOptions, getTaskStatus, addRecipeFromUrl, apiFetchRecipe, apiReprocessRecipe } from '../api/api.ts';
import { useTaskStore } from './taskStore.ts';
import { useSelectionStore } from './selectionStore.ts';

const LOCAL_STORAGE_AUTH_KEY = 'recipe_store_auth_token';

export interface IngredientOption {
  ingredient_id: number;
  ingredient_name: string;
  productOptions?: Product[];
  selectedProductId?: string;
}

export interface Unit {
  unit: string;
  quantity: number;
}

export interface Product {
  brand: string;
  countryOrigin: string;
  id: string;
  image_url: string;
  pack_size: string;
  price: number;
  title: string;
  selectedUnit: Unit;
  units: Unit[];
  unitsRequired?: number;
}

export interface RecipeIngredient {
  id: number;
  ingredient_id: number;
  ingredient_name: string;
  raw_text: string;
  quantity: number;
  unit: string;
  normalized_unit: string;
  normalized_quantity: number;
  recipe_id: number;
  preparation: string;
  ingredientOptions?: IngredientOption[];
  selectedIngredientOptionId?: number;
}

export interface Recipe {
  id: number;
  recipeIngredients: Record<number, RecipeIngredient>;
  title: string;
  instruction_lines: string[];
  picture_link: string;
  preparation_minutes?: number;
  cooking_minutes?: number;
  servings?: number;
  source_url?: string;
}

interface AddRecipeState {
  loading: boolean;
  currentTaskId: string | null;
  error: string | null;
}

interface IngredientLoadingState {
  ingredients?: boolean;
  products?: {
    [ingredientOptionId: number]: boolean;
  };
}

interface LoadingStates {
  [recipeId: number]: {
    [recipeIngredientId: number]: IngredientLoadingState;
  };
}

interface RecipeStoreState {
  recipes: Record<number, Recipe>;
  setIngredientOptions: (
    recipeId: number, 
    recipeIngredientId: number, 
    options: IngredientOption[]
  ) => void;
  fetchClosestIngredients: (
    recipeId: number, 
    recipeIngredientId: number
  ) => Promise<void>;
  getIngredientOptions: (
    recipeId: number,
    recipeIngredientId: number
  ) => IngredientOption[];
  setProductOptions: (
    recipeId: number,
    recipeIngredientId: number,
    ingredientOptionId: number,
    products: Product[]
  ) => void;
  getProductOptions: (
    recipeId: number,
    recipeIngredientId: number,
    ingredientOptionId: number
  ) => Product[];
  fetchProductOptions: (
    recipeId: number,
    recipeIngredientId: number,
    ingredientId: number,
    count?: number
  ) => Promise<void>;
  addRecipe: AddRecipeState;
  addRecipeFromUrl: (url: string) => Promise<void>;
  isValidRecipeUrl: (url: string) => boolean;
  loadingRecipe: boolean;
  recipeError: string | null;
  reprocessRecipe: (recipeId: number, url: string) => Promise<void>;
  fetchRecipe: (recipeId: number) => Promise<void>;
  loadingStates: LoadingStates;
  setIngredientLoading: (recipeId: number, recipeIngredientId: number, loading: boolean) => void;
  setProductLoading: (recipeId: number, recipeIngredientId: number, ingredientOptionId: number, loading: boolean) => void;
}

export const useRecipeStore = create<RecipeStoreState>((set, get) => ({
  recipes: {},
  setIngredientOptions: (recipeId, recipeIngredientId, options) => 
    set((state) => {
      const recipe = state.recipes[recipeId];
      if (!recipe) return state;
  
      return {
        recipes: {
          ...state.recipes,
          [recipeId]: {
            ...recipe,
            recipeIngredients: {
              ...recipe.recipeIngredients,
              [recipeIngredientId]: {
                ...recipe.recipeIngredients[recipeIngredientId],
                ingredientOptions: options
              }
            }
          }
        }
      };
    }),

  setIngredientLoading: (recipeId, recipeIngredientId, loading) => 
    set(state => ({
      loadingStates: {
        ...state.loadingStates,
        [recipeId]: {
          ...state.loadingStates[recipeId],
          [recipeIngredientId]: {
            ...state.loadingStates[recipeId]?.[recipeIngredientId],
            ingredients: loading
          }
        }
      }
    })),

  setProductLoading: (recipeId, recipeIngredientId, ingredientOptionId, loading) =>
    set(state => ({
      loadingStates: {
        ...state.loadingStates,
        [recipeId]: {
          ...state.loadingStates[recipeId],
          [recipeIngredientId]: {
            ...state.loadingStates[recipeId]?.[recipeIngredientId],
            products: {
              ...state.loadingStates[recipeId]?.[recipeIngredientId]?.products,
              [ingredientOptionId]: loading
            }
          }
        }
      }
    })),

  fetchClosestIngredients: async (recipeId, recipeIngredientId) => {
    const state = get();

    // Check if ingredients already exist
    const existingIngredients = state.getIngredientOptions(recipeId, recipeIngredientId);
    if (existingIngredients && existingIngredients.length > 0) {
      return;
    }
  
    get().setIngredientLoading(recipeId, recipeIngredientId, true);
    try {
      const ingredients = await getClosestIngredients(recipeId, recipeIngredientId);
      get().setIngredientOptions(
        recipeId,
        recipeIngredientId,
        ingredients.map(ingredient => ({
          ingredient_id: ingredient.ingredient_id,
          ingredient_name: ingredient.ingredient_name,
        }))
      );
  
      // Fetch product options for first ingredient if needed
      if(ingredients.length > 0) {
        const productOptions = get().getProductOptions(recipeId, recipeIngredientId, ingredients[0].ingredient_id);
        if (!productOptions || productOptions.length === 0) {
          await get().fetchProductOptions(recipeId, recipeIngredientId, ingredients[0].ingredient_id);
        }
      }
    } finally {
      get().setIngredientLoading(recipeId, recipeIngredientId, false);
    }
  },

  getIngredientOptions: (recipeId, recipeIngredientId) => {
    const state = get();
    return state.recipes[recipeId]?.recipeIngredients[recipeIngredientId]?.ingredientOptions || [];
  },

  setProductOptions: (recipeId, recipeIngredientId, ingredientOptionId, products) => {
    return set((state) => ({
      recipes: {
        ...state.recipes,
        [recipeId]: {
          ...state.recipes[recipeId],
          recipeIngredients: {
            ...state.recipes[recipeId]?.recipeIngredients,
            [recipeIngredientId]: {
              ...state.recipes[recipeId]?.recipeIngredients[recipeIngredientId],
              ingredientOptions: state.recipes[recipeId]?.recipeIngredients[recipeIngredientId]
                ?.ingredientOptions?.map(option =>
                  option.ingredient_id === ingredientOptionId
                    ? {
                        ...option,
                        productOptions: products,
                        selectedProductId: option.selectedProductId || undefined
                      }
                    : option
                ),
            },
          },
        },
      },
    }))
  },

  getProductOptions: (recipeId, recipeIngredientId, ingredientOptionId) => {
    const state = get();
    const ingredientOption = state.recipes[recipeId]?.recipeIngredients[recipeIngredientId]
      ?.ingredientOptions?.find(opt => opt.ingredient_id === ingredientOptionId);
    return ingredientOption?.productOptions || [];
  },

  fetchProductOptions: async (recipeId, recipeIngredientId, ingredientId, count = 20) => {
    const state = get();

    // Don't fetch if already loaded
    const existingProducts = state.getProductOptions(recipeId, recipeIngredientId, ingredientId);
    if (existingProducts && existingProducts.length >= count) {
      return;
    }

    // Don't fetch if already loading
    const isLoading = state.loadingStates[recipeId]?.[recipeIngredientId]?.products?.[ingredientId];
    if (isLoading) {
      return;
    }

    state.setProductLoading(recipeId, recipeIngredientId, ingredientId, true);
    try {
      const products = await getProductOptions(ingredientId, count);
      state.setProductOptions(
        recipeId,
        recipeIngredientId,
        ingredientId,
        products
      );
    } finally {
      state.setProductLoading(recipeId, recipeIngredientId, ingredientId, false);
    }
  },

  addRecipe: {
    loading: false,
    currentTaskId: null,
    error: null
  },

  isValidRecipeUrl: (url: string) => {
    return /^(http|https):\/\/[^ "]+$/.test(url);
  },

  addRecipeFromUrl: async (url: string) => {
    if (!get().isValidRecipeUrl(url)) {
      set(state => ({
        addRecipe: {
          ...state.addRecipe,
          error: 'Invalid URL format'
        }
      }));
      return;
    }

    set(state => ({
      addRecipe: {
        ...state.addRecipe,
        loading: true,
        error: null
      }
    }));

    try {
      const data = await addRecipeFromUrl(url);
      
      if (data?.task_id) {
        set(state => ({
          addRecipe: {
            ...state.addRecipe,
            currentTaskId: data.task_id
          }
        }));
        useTaskStore.getState().pollTask(data.task_id);
      }
    } catch (error) {
      set(state => ({
        addRecipe: {
          ...state.addRecipe,
          loading: false,
          error: error.message
        }
      }));
    }
  },

  loadingRecipe: false,
  recipeError: null,

  fetchRecipe: async (recipeId: number) => {
    const existingRecipe = get().recipes[recipeId];
    if (existingRecipe) {
      return;
    }
    set({ loadingRecipe: true, recipeError: null });

    try {
      const recipe = await apiFetchRecipe(recipeId);
      
      // Handle empty normalized units
      for (const ingredient of Object.values(recipe.recipeIngredients)) {
        if (ingredient.normalized_unit === '') {
          ingredient.normalized_unit = 'count';
        }
      }
      
      // Store the recipe in state
      set(state => ({
        recipes: {
          ...state.recipes,
          [recipeId]: recipe
        },
        loadingRecipe: false
      }));
  
      await useSelectionStore.getState().loadSelections(recipeId);
  
    } catch (err) {
      set({ recipeError: err.message, loadingRecipe: false });
      console.error(err);
    }
  },
  
  reprocessRecipe: async (recipeId: number, url: string) => {
    set({ loadingRecipe: true });
    try {
      const response = await apiReprocessRecipe(recipeId, url);
      
      if (response?.task_id) {
        // Call pollTask from taskStore instead
        useTaskStore.getState().pollTask(response.task_id);
      }
    } catch (error) {
      set({ recipeError: error.message, loadingRecipe: false }); 
      console.error("Error reprocessing recipe:", error);
    }
  },

  loadingStates: {},
}));
