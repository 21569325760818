import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { MealServingSelection, MealPrepSelection } from '../api/types';
import { createMealPrepSelection, deleteMealPlanSelection, getMealPrepSelections, createServingSelection, deleteServingSelection, getServingSelections } from '../api/api.ts';
import { useAuthStore } from './authStore.ts';
import { useSelectionStore } from './selectionStore.ts';
import { v4 as uuidv4 } from 'uuid';
import { useRecipeStore } from './recipeStore.ts';

interface MealPlanState {
  prepSelections: MealPrepSelection[];
  servingSelections: MealServingSelection[];
  isLoading: boolean;
  error: string | null;
  
  // Prep Selection Actions
  setMealPrepSelections: (selections: MealPrepSelection[]) => void;
  addMealPrepSelection: (selection: MealPrepSelection) => void;
  removeMealPrepSelection: (id: number) => void;
  
  // Serving Selection Actions
  setServingSelections: (selections: MealServingSelection[]) => void;
  removeServingSelection: (id: string) => void;
  
  // Common Actions
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
  fetchMealPlan: () => Promise<void>;
  removeAllSelectionsForRecipe: (recipeId: number) => Promise<void>;
  
  // Add to plan actions
  addToMealPrepPlan: (recipeId: number, servings: number) => Promise<void>;
  addToServingsPlan: (recipeId: number, mealPrepId: number, servings: number, date: Date, mealName: string) => Promise<void>;
}

export const useMealPlanStore = create<MealPlanState>()(
  devtools(
    (set, get) => ({
      prepSelections: [],
      servingSelections: [],
      isLoading: false,
      error: null,

      fetchMealPlan: async () => {
        set({ isLoading: true, error: null });
        const authToken = await useAuthStore.getState().getValidFirebaseToken();
        try {
          const [prepSelections, servingSelections] = await Promise.all([
            getMealPrepSelections(authToken),
            getServingSelections(authToken)
          ]);

          // Fetch any missing recipes
          const recipeStore = useRecipeStore.getState();
          const uniqueRecipeIds = new Set([
            ...prepSelections.map(prep => prep.recipe_id),
            ...servingSelections.map(serving => serving.recipe_id)
          ]);

          await Promise.all(
            Array.from(uniqueRecipeIds).map(recipeId => 
              recipeStore.fetchRecipe(recipeId)
            )
          );

          set({ prepSelections, servingSelections, isLoading: false });
        } catch (error) {
          set({ 
            error: error instanceof Error ? error.message : 'Failed to fetch meal plans',
            isLoading: false 
          });
        }
      },

      setMealPrepSelections: (prepSelections) => set({ prepSelections, error: null }),
      setServingSelections: (servingSelections) => set({ servingSelections, error: null }),

      addMealPrepSelection: (selection) =>
        set((state) => ({
          prepSelections: [...state.prepSelections, selection],
          error: null,
          isLoading: false
        })),

      removeMealPrepSelection: async (id) => {
        set({ isLoading: true, error: null });
        set((state) => ({
          prepSelections: state.prepSelections.filter(s => s.id !== id),
          error: null
        }));
        try {
          await deleteMealPlanSelection(id);
          set({ isLoading: false });
        } catch (error) {
          // Revert optimistic update
          get().fetchMealPlan();
          set({ isLoading: false });
        }
      },

      removeServingSelection: async (id) => {
        set({ isLoading: true, error: null });
        set((state) => ({
          servingSelections: state.servingSelections.filter(s => s.id !== id),
          error: null
        }));
        try {
          await deleteServingSelection(id);
          set({ isLoading: false });
        } catch (error) {
          // Revert optimistic update
          get().fetchMealPlan();
          set({ isLoading: false });
        }
      },

      setLoading: (loading) => set({ isLoading: loading }),
      setError: (error) => set({ error }),

      addToMealPrepPlan: async (recipeId, servings) => {
        set({ isLoading: true, error: null });
        const authToken = await useAuthStore.getState().getValidFirebaseToken();
        const selectionStore = useSelectionStore.getState();
        
        try {
          await selectionStore.saveSelections(recipeId);
          const selection = await createMealPrepSelection({
            recipe_id: recipeId,
            servings: servings
          }, authToken);
          
          set(state => ({
            prepSelections: [...state.prepSelections, selection],
            isLoading: false
          }));
        } catch (error) {
          set({ 
            error: error instanceof Error ? error.message : 'Failed to add to meal prep plan',
            isLoading: false 
          });
          throw error;
        }
      },

      addToServingsPlan: async (recipeId, mealPrepId, servings, date, mealName) => {
        set({ isLoading: true, error: null });
        const authToken = await useAuthStore.getState().getValidFirebaseToken();
        try {
          const selection = await createServingSelection({
            recipe_id: recipeId,
            meal_prep_id: mealPrepId,
            servings,
            date,
            meal_name: mealName,
            id: uuidv4()
          }, authToken);

          set(state => ({
            servingSelections: [...state.servingSelections, selection],
            isLoading: false
          }));
        } catch (error) {
          set({ 
            error: error instanceof Error ? error.message : 'Failed to add to meal plan',
            isLoading: false 
          });
          throw error;
        }
      },

      removeAllSelectionsForRecipe: async (recipeId) => {
        set({ isLoading: true, error: null });
        try {
          const prepToRemove = useMealPlanStore.getState().prepSelections
            .filter(s => s.recipe_id === recipeId);
          const servingsToRemove = useMealPlanStore.getState().servingSelections
            .filter(s => s.recipe_id === recipeId);
            
          await Promise.all([
            ...prepToRemove.map(selection => deleteMealPlanSelection(selection.id)),
            ...servingsToRemove.map(selection => deleteServingSelection(selection.id))
          ]);

          set(state => ({
            prepSelections: state.prepSelections.filter(s => s.recipe_id !== recipeId),
            servingSelections: state.servingSelections.filter(s => s.recipe_id !== recipeId),
            isLoading: false
          }));
        } catch (error) {
          set({ 
            error: error instanceof Error ? error.message : 'Failed to remove selections',
            isLoading: false 
          });
          throw error;
        }
      }
    })
  )
);