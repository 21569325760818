import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MealPrepSelection } from "../api/types.ts";
import { useMealPlanStore } from "../state/mealPlanStore.ts";
import { useRecipeStore } from "../state/recipeStore.ts";
import { MdEdit, MdClose } from 'react-icons/md';
import { useSelectionStore } from "../state/selectionStore.ts";

const RecipeCard = ({
    children,
    onDelete,
    recipeId
}: {
    children: React.ReactNode,
    onDelete: () => void,
    recipeId: number
}) => {
    const navigate = useNavigate();
    const { getRecipePriceInfo } = useSelectionStore();
    const { totalUsedCost } = getRecipePriceInfo(recipeId);

    const buttonStyles = {
        padding: '6px',
        backgroundColor: '#f8f9fa',
        border: '1px solid #dee2e6',
        borderRadius: '50%',
        cursor: 'pointer',
        width: '28px',
        height: '28px',
        minWidth: '28px',
        minHeight: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.2s ease',
        boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
        margin: 0
    };

    return (
        <div className="recipe-container" style={{
            position: 'relative',
            padding: '16px',
            margin: '4px',
            border: '1px solid #e9ecef',
            borderRadius: '8px',
            backgroundColor: 'white',
            width: '200px',
            flexShrink: 0,
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
            transition: 'all 0.2s ease',
            cursor: 'pointer',
            ':hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
            }
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
            }}>
                {children}
            </div>
            <div style={{
                position: 'absolute',
                top: '12px',
                right: '12px',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignItems: 'center'
            }}>
                <div style={{
                    width: '28px',
                    height: '28px'
                }}>
                    <button
                        onClick={onDelete}
                        style={{
                            ...buttonStyles,
                            color: '#dc3545',
                        }}
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = '#fee2e2';
                            e.currentTarget.style.borderColor = '#fecaca';
                            e.currentTarget.style.color = '#dc2626';
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = '#f8f9fa';
                            e.currentTarget.style.borderColor = '#dee2e6';
                            e.currentTarget.style.color = '#dc3545';
                        }}
                    >
                        <MdClose size={18} />
                    </button>
                </div>
                <div style={{
                    width: '28px',
                    height: '28px'
                }}>
                    <button
                        onClick={() => navigate(`/recipe-view/${recipeId}`)}
                        style={{
                            ...buttonStyles,
                            color: '#6c757d',
                        }}
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = '#e9ecef';
                            e.currentTarget.style.color = '#495057';
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = '#f8f9fa';
                            e.currentTarget.style.color = '#6c757d';
                        }}
                    >
                        <MdEdit size={16} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export const RecipeSelectionBanner: React.FC = () => {
    const { isLoading: mealPlanLoading, prepSelections: mealPlan } = useMealPlanStore();
    const { recipes } = useRecipeStore();
    const { getRecipePriceInfo } = useSelectionStore();
    const [deletingIds, setDeletingIds] = useState<number[]>([]);
    const [error, setError] = useState<string | null>(null);
    
    const handleDelete = (selectionId: number) => {
        const mealPlanStore = useMealPlanStore.getState();
        try {
            setDeletingIds(prev => [...prev, selectionId]);
            mealPlanStore.removeAllSelectionsForRecipe(selectionId);
        } catch (err) {
            console.error('Failed to delete selection:', err);
            setError(err instanceof Error ? err.message : 'Failed to delete selection');
            mealPlanStore.fetchMealPlan();
        } finally {
            setDeletingIds(prev => prev.filter(id => id !== selectionId));
        }
    };

    const allRecipesLoaded = mealPlan.every(plannedMeal => recipes[plannedMeal.recipe_id]);

    const totalCosts = mealPlan.reduce((acc, plannedMeal) => {
        const { totalGroceryCost, totalUsedCost, totalLeftoverCost } = getRecipePriceInfo(plannedMeal.recipe_id);
        return {
            groceryCost: acc.groceryCost + totalGroceryCost,
            usedCost: acc.usedCost + totalUsedCost,
            leftoverCost: acc.leftoverCost + totalLeftoverCost
        };
    }, { groceryCost: 0, usedCost: 0, leftoverCost: 0 });

    return (
        <div style={{ 
            backgroundColor: 'white',
            borderTop: '1px solid #dee2e6',
            padding: '12px',
            width: '100%',
            boxSizing: 'border-box',
            boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 1000,
        }}>
            <div style={{ 
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
            }}>
                {/* Header Row */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '8px'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <span style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>Meal Plan:</span>
                        {mealPlan.length > 0 && !mealPlanLoading && allRecipesLoaded ? (
                            <span style={{ color: '#495057', fontSize: '1.1rem' }}>
                                ${totalCosts.usedCost.toFixed(2)}
                                {totalCosts.leftoverCost > 0 && 
                                    <span style={{ color: '#666', fontSize: '0.9rem' }}>+${totalCosts.leftoverCost.toFixed(2)}</span>
                                }
                            </span>
                        ) : (
                            <span style={{ color: '#666', fontStyle: 'italic', fontSize: '0.9rem' }}>empty</span>
                        )}
                    </div>
                    <Link
                        to="/calendar"
                        style={{
                            fontSize: '0.875rem',
                            color: '#007bff',
                            textDecoration: 'none',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            transition: 'background-color 0.2s ease',
                            whiteSpace: 'nowrap'
                        }}
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                    >
                        View Plan →
                    </Link>
                </div>
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                overflowX: 'auto',
                gap: '1rem',
                padding: '4px 8px',
                WebkitOverflowScrolling: 'touch',
                msOverflowStyle: '-ms-autohiding-scrollbar'
            }}>
                {mealPlanLoading || !allRecipesLoaded ? (
                    <span style={{ color: "#666", fontStyle: "italic", fontSize: "1rem" }}>Loading...</span>
                ) : mealPlan.length === 0 ? (
                    <span style={{ color: "#666", fontStyle: "italic", fontSize: "1rem" }}>No meals selected</span>
                ) : (
                    <div style={{ display: "flex", gap: "1rem" }}>
                        {mealPlan.map((plannedMeal: MealPrepSelection) => {
                            const recipe = recipes[plannedMeal.recipe_id];
                            if (!recipe) return null;

                            return (
                                <RecipeCard
                                    key={plannedMeal.id}
                                    onDelete={() => handleDelete(plannedMeal.id)}
                                    recipeId={recipe.id}
                                >
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "1rem"
                                    }}>
                                        <img
                                            src={recipe.picture_link}
                                            alt={recipe.title}
                                            style={{
                                                width: "48px",
                                                height: "48px",
                                                objectFit: "cover",
                                                borderRadius: "6px"
                                            }}
                                        />
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "4px"
                                        }}>
                                            <span style={{
                                                fontSize: "1rem",
                                                width: "120px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                fontWeight: "500",
                                                color: "#495057"
                                            }}>
                                                {recipe.title}
                                            </span>
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "8px",
                                                fontSize: "0.875rem",
                                                color: "#666"
                                            }}>
                                                <span style={{
                                                    fontWeight: "500",
                                                    color: "#495057",
                                                    backgroundColor: "#f8f9fa",
                                                    padding: "2px 6px",
                                                    borderRadius: "4px",
                                                    border: "1px solid #dee2e6"
                                                }}>
                                                    ${getRecipePriceInfo(recipe.id).totalUsedCost.toFixed(2)}
                                                </span>
                                                <span>{plannedMeal.servings} servings</span>
                                            </div>
                                        </div>
                                    </div>
                                </RecipeCard>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};