import React, { useCallback, useState } from 'react';
import { addItemsToCart } from '../api/api.ts';
import { Recipe } from '../state/recipeStore.ts';
import { useAuthStore } from '../state/authStore.ts';
import { useSelectionStore } from '../state/selectionStore.ts';

interface AddToCartButtonProps {
  recipe: Recipe;
  modality: string;
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({ recipe, modality }) => {
  const hasKrogerToken = useAuthStore(state => state.krogerToken) !== null;
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const openKrogerCart = () => {
    window.open('https://www.kroger.com/cart', '_blank');
  };

  const addToCart = useCallback(async () => {
    if (!hasKrogerToken) {
      setError('Please login to add items to cart');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const selectionStore = useSelectionStore.getState();
      await selectionStore.saveSelections(recipe.id);

      const items = Object.values(recipe.recipeIngredients)
        .filter(ingredient => 
          selectionStore.getSelectedForPurchase(recipe.id, ingredient.id)
        )
        .map(ingredient => {
          const selectedProduct = selectionStore.getSelectedProduct(recipe.id, ingredient.id);
          
          if (!selectedProduct) {
            throw new Error(`No product selected for ingredient ${ingredient.id}`);
          }

          const quantity = selectionStore.getSelectedQuantity(recipe.id, ingredient.id);

          return {
            quantity,
            upc: selectedProduct.id,
            modality
          };
        });

      if (items.length === 0) {
        setError('No items selected for purchase');
        return;
      }

      const krogerAuthToken = await useAuthStore.getState().getValidKrogerToken();

      await addItemsToCart(items, krogerAuthToken);
      setOrderPlaced(true);
      openKrogerCart();
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to add items to cart');
      setOrderPlaced(false);
    } finally {
      setIsLoading(false);
    }
  }, [hasKrogerToken, recipe, modality]);

  return (
    <button 
      style={{
        backgroundColor: '#0071ce',
        flex: 1,
        color: 'white',
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        cursor: isLoading ? 'not-allowed' : 'pointer',
        transition: 'background-color 0.3s ease-in-out',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        minWidth: '150px',
        height: '40px',
        opacity: isLoading ? 0.7 : 1,
        ':hover': {
          backgroundColor: '#005a9e'
        }
      }}
      onClick={addToCart}
      disabled={isLoading}
    >
      <img 
        src="/kroger_white.png" 
        alt="Kroger Logo" 
        style={{
          width: '64px',
          marginRight: '8px',
          objectFit: 'contain'
        }}
      />
      {isLoading ? 'Adding...' : orderPlaced ? 'Added to Cart ' : 'Add to Cart '}
      {orderPlaced && <span style={{ marginLeft: '8px' }}>&#10004;</span>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </button>
  );
};

export default AddToCartButton;