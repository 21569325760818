import React, { useState, useEffect, useRef } from 'react';
import API_URL from '../config.js';
import WebRecipeSelector from '../WebRecipeSelect/WebRecipeSelector.tsx';
import ThemeScroll from './ThemeScroll.tsx';
import RecipeTitleScroll from './RecipeTitleScroll.tsx';
import { RecipeSelectionBanner } from '../components/RecipeSelectionBanner.tsx'
import { useParams, useNavigate } from 'react-router-dom';
import { NavToggleHeader } from '../components/NavToggleHeader.tsx';

interface RecipeOption {
  title: string;
  site_url: string;
  main_site: string;
  rating: number;
  description: string;
}

// Define animations as objects
const animations = {
  pulse: `
    @keyframes pulse {
      0% { transform: scale(0.95); opacity: 0.8; }
      50% { transform: scale(1.05); opacity: 1; }
      100% { transform: scale(0.95); opacity: 0.8; }
    }
  `,
  roll: `
    @keyframes roll {
      0% { transform: translateX(100%) rotate(0deg); }
      100% { transform: translateX(-100%) rotate(-720deg); }
    }
  `
};

// Update LoadingAnimation component with inline styles
export const LoadingAnimation: React.FC = () => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '2rem'
  }}>
    <style>
      {animations.pulse}
      {animations.roll}
    </style>
    <img 
      src="/logo512.png" 
      alt="Loading..." 
      style={{
        width: '48px',
        height: '48px',
        transformOrigin: 'center center',
        animation: 'roll 2s linear infinite, pulse 1s infinite ease-in-out',
        willChange: 'transform'
      }}
    />
  </div>
);

const FOOTER_HEIGHT = '160px';

const InspirationSearch: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewMode, setIsReviewMode] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [isRecipeSelectMode, setIsRecipeSelectMode] = useState(false);
  const [recipeOptions, setRecipeOptions] = useState<RecipeOption[]>([]);
  const [selectedRecipe, setSelectedRecipe] = useState('');
  const { theme, recipeName } = useParams<{ theme?: string, recipeName?: string }>();
  const searchParams = new URLSearchParams(window.location.search);
  const pageParam = searchParams.get('p');
  const startIndex = pageParam ? parseInt(pageParam, 10) : 0;
  const [mode, setMode] = useState<'search' | 'copilot'>('copilot');
  const contentRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (theme) {
      setSelectedTheme(decodeURIComponent(theme).replace(/_/g, ' '));
      setIsReviewMode(true);
    } else {
      setSelectedTheme('');
      setIsReviewMode(false);
    }
    
    if (recipeName) {
      setSelectedRecipe(decodeURIComponent(recipeName).replace(/_/g, ' '));
      setIsRecipeSelectMode(true);
      fetchRecipeOptions(decodeURIComponent(recipeName).replace(/_/g, ' '));
    } else {
      setSelectedRecipe('');
      setIsRecipeSelectMode(false);
    }
  }, [theme, recipeName]);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const scrollY = contentRef.current.scrollTop;
        const scrollHeight = contentRef.current.scrollHeight;
        const clientHeight = contentRef.current.clientHeight;
        
        // Check if we're within 100px of the bottom OR if the content doesn't fill the viewport
        const isNearBottom = scrollHeight - (scrollY + clientHeight) < 200;
        const contentDoesntFillViewport = scrollHeight <= clientHeight;
        
        if (isNearBottom || contentDoesntFillViewport) {
          console.log('Near bottom or content doesn\'t fill viewport!');
          // Trigger content load in ThemeScroll
          window.postMessage('REACHED_BOTTOM', window.location.origin);
        }

        if(contentDoesntFillViewport) {
          // Repeat check every 100ms until content fills viewport
          setTimeout(handleScroll, 100);
        }
        
        setScrollPosition(scrollY);
      }
    };

    // Add initial check when component mounts
    handleScroll();

    // Single scroll listener on content div
    const currentRef = contentRef.current;
    currentRef?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      currentRef?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleThemeClick = (suggestion: string) => {
    const { text } = extractEmojiAndText(suggestion);
    setSelectedTheme(text);
    setIsReviewMode(true);
    navigate(`/inspiration/${text.replace(/ /g, '_')}`);
  };

  const fetchRecipeOptions = async (recipeTitle: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}/copilot/recipe-options/${encodeURIComponent(recipeTitle)}`);
      const options: RecipeOption[] = await response.json();
      setRecipeOptions(options);
    } catch (error) {
      console.error('Error fetching recipe options:', error);
      setRecipeOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const extractEmojiAndText = (str: string) => {
    // Updated regex that handles compound emojis and modifiers
    const emojiRegex = /^[\p{Emoji}\u{FE0F}\u{1F3FB}-\u{1F3FF}]+([\u{200D}\p{Emoji}\u{FE0F}\u{1F3FB}-\u{1F3FF}]*[\p{Emoji}\u{FE0F}])?/u;
    const match = str.match(emojiRegex);
    
    if (match) {
      const emoji = match[0];
      const text = str.slice(emoji.length).trim();
      return { emoji, text };
    }
    
    return { emoji: '🍽️', text: str.trim() };
  };

  const handleCancel = () => {
    navigate('/inspiration');
  };

  const renderContent = () => {
    if (isRecipeSelectMode) {
      return (
        <WebRecipeSelector
          isLoading={isLoading}
          recipeOptions={recipeOptions}
          onBack={() => {
            setIsRecipeSelectMode(false);
            setRecipeOptions([]);
          }}
        />
      );
    }

    if (isReviewMode) {
      return (
        <RecipeTitleScroll
          selectedTheme={selectedTheme}
          onRecipeClick={(recipeName) => {
            setSelectedRecipe(recipeName);
            setIsRecipeSelectMode(true);
            fetchRecipeOptions(recipeName);
          }}
          onBack={handleCancel}
          extractEmojiAndText={extractEmojiAndText}
        />
      );
    }

    return (
      <ThemeScroll
        onThemeClick={handleThemeClick}
        extractEmojiAndText={extractEmojiAndText}
        startIndex={startIndex}
      />
    );
  };

  const triggerLoadMore = () => {
    window.postMessage('REACHED_BOTTOM', window.location.origin);
  };

  return (
    <div 
      ref={contentRef}
      style={{ 
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'hidden',
        minHeight: '100vh'
      }}
    >
      <NavToggleHeader mode={mode}/>

      <div style={{ 
        display: 'flex', 
        flexDirection: 'column',
        paddingBottom: FOOTER_HEIGHT,
        marginBottom: 0,
        marginTop: 0,
        paddingTop: 0,
        width: '100%'
      }}>
        {renderContent()}
        
        {!isRecipeSelectMode && (
          <button
            onClick={triggerLoadMore}
            style={{
              margin: '20px auto',
              padding: '10px 20px',
              borderRadius: '8px',
              border: 'none',
              backgroundColor: '#007bff',
              color: 'white',
              cursor: 'pointer',
              fontSize: '16px'
            }}
          >
            Load More
          </button>
        )}
      </div>
      
      <RecipeSelectionBanner />
    </div>
  );
};

export default InspirationSearch;