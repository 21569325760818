// CalendarPlanner.tsx
import React, { useState, useEffect } from 'react';
import { 
  DndContext, 
  DragEndEvent,
  DragOverlay,
  pointerWithin,
  DragStartEvent,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { MealPrepSelection } from '../api/types.ts';
import { useMealPlanStore } from '../state/mealPlanStore.ts';
import { useRecipeStore } from '../state/recipeStore.ts';
import Calendar from "./Calendar.tsx";
import { MealPlanTray } from './MealPlanTray.tsx';
import { NavToggleHeader } from '../components/NavToggleHeader.tsx';

export const CalendarPlanner: React.FC = () => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [mode, setMode] = useState<'search' | 'copilot' | null>(null);
  
  const { 
    fetchMealPlan,
    addToServingsPlan,
    isLoading: mealPlanLoading
  } = useMealPlanStore();

  const { recipes } = useRecipeStore();

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  useEffect(() => {
    fetchMealPlan();
  }, []);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const handleDragMove = () => {
    document.body.style.overflow = 'hidden';
  };

  const handleDragEnd = (event: DragEndEvent) => {
    document.body.style.overflow = 'auto';
    
    const { active, over } = event;
    setActiveId(null);
  
    if (!over || !active) return;
  
    try {
      const mealData = active.data.current?.mealData as MealPrepSelection;
      const [dateString, mealType] = over.id.toString().split('|');
      const targetDate = new Date(dateString);
  
      if (isNaN(mealData.recipe_id) || !targetDate) {
        console.error('Invalid recipe ID or date');
        return;
      }
      
      addToServingsPlan(
        mealData.recipe_id,
        mealData?.id!,
        1,
        targetDate,
        mealType
      );
    } catch (error) {
      console.error('Error in drag end handler:', error);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      collisionDetection={pointerWithin}
    >
      <div>
        <NavToggleHeader mode={mode!} />
        
        <div style={{ display: "flex", flexDirection: "column", gap: "2rem", marginTop: "2rem" }}>
          <div>
            <MealPlanTray />
          </div>
          
          <div style={{ overflowX: "auto" }}>
            <h3 style={{ marginLeft: "1rem" }}>Week View</h3>
            <Calendar isLoading={mealPlanLoading} />
          </div>
        </div>
      </div>

      <DragOverlay>
        {activeId ? (
          <div style={{
            padding: "0.5rem",
            backgroundColor: "white",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
            borderRadius: "4px"
          }}>
            {recipes[parseInt(activeId.split('-')[1])]?.title}
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};