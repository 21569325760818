import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import RecipeDescription from '../components/RecipeDescription.tsx';
import RecipeIngredients from '../components/RecipeIngredients.tsx';
import { FaSyncAlt } from 'react-icons/fa';
import { useRecipeStore } from '../state/recipeStore.ts';

type RouteParams = {
    recipeId: string;
}

const RecipeView: React.FC = () => {
    const params = useParams<RouteParams>();
    const recipeId = parseInt(params.recipeId!);
    const { 
        recipes,
        reprocessRecipe,
        loadingRecipe: loading,
        recipeError: error,
        fetchRecipe
    } = useRecipeStore();
    const recipe = recipes[recipeId];
    const [statusIndicator, setStatusIndicator] = useState<string>('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleRecompute = useCallback(() => {
        if (recipe && recipe.source_url) {
            reprocessRecipe(recipeId, recipe.source_url);
        }
    }, [recipe, recipeId, reprocessRecipe]);

    useEffect(() => {
        console.log('loading', loading)
        console.log('recipe', recipe)
        console.log('error', error)
        if (!recipe && !loading && !error) {
            fetchRecipe(recipeId);
        }
    }, [recipeId, recipe, loading, error, fetchRecipe]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!recipe || loading) {
        return <div>Loading...</div>;
    }

    const styles = {
        recipeView: {
            position: 'relative',
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            overflowX: 'hidden',
        },
        recipeContainer: {
            display: 'grid',
            gridTemplateColumns: windowWidth > 1300 ? '2fr 1fr' : '1fr',
            gap: '5px',
            padding: '5px',
            width: '100%',
            minWidth: '0',
            maxWidth: '100%',
            boxSizing: 'border-box',
        },
        recipeSection: {
            minWidth: '0',
            width: '100%',
            maxWidth: '100%',
            boxSizing: 'border-box',
            padding: '5px',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            hyphens: 'auto',
            overflow: 'hidden',
        },
        ingredientsSection: {
            borderLeft: windowWidth > 1300 ? '1px solid #ccc' : 'none',
        },
    };

    return (
        <div className="recipe-view" style={styles.recipeView}>
            <div className="recipe-container" style={styles.recipeContainer}>
                <div className="recipe-section" style={styles.recipeSection}>
                    <RecipeDescription recipe={recipe} />
                </div>
                <div className="recipe-section ingredients-section" style={styles.ingredientsSection}>
                    <RecipeIngredients recipe={recipe} />
                </div>
            </div>
            <div className="refresh-button">
                <FaSyncAlt 
                    className={loading ? 'icon-spin' : ''} 
                    onClick={handleRecompute} 
                />
            </div>
            {loading && (
                <div className="loading-container">
                    <div className="spinner"></div>
                    <p>Recomputing...{statusIndicator}</p>
                </div>
            )}
        </div>
    );
};

export default RecipeView;