import React, { useState, useCallback, useEffect } from 'react';
import RecipeSearchResult from './RecipeSearchResult.tsx';
import { searchRecipes } from '../api/api.ts';
import { useRecipeStore } from '../state/recipeStore.ts';
import { useTaskStore } from '../state/taskStore.ts';
import { RecipeHeaderResponse } from '../api/types.ts';
import WebRecipeSelector from '../WebRecipeSelect/WebRecipeSelector.tsx';
import API_URL from '../config.js';
import { FaGlobe } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner.tsx';

const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func(...args);
        }, wait);
    };
};

const SearchBar = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState('');
    const [recipes, setRecipes] = useState<RecipeHeaderResponse[]>([]);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { 
      addRecipe: { loading, error, currentTaskId },
      isValidRecipeUrl,
      addRecipeFromUrl,
    } = useRecipeStore();
    const { getTaskStatus } = useTaskStore();
    const limit = 10;
    const [recipeOptions, setRecipeOptions] = useState([]);
    const [isWebSearching, setIsWebSearching] = useState(false);

    const handleSearch = useCallback(debounce(async () => {
        if (isValidRecipeUrl(query)) {
            addRecipeFromUrl(query);
        } else {
            setIsLoading(true);
            try {
                const results = await searchRecipes(query, skip, limit + 1);
                const displayResults = results.slice(0, limit);
                if (skip === 0) {
                    setRecipes(displayResults);
                } else {
                    setRecipes(prev => [...prev, ...displayResults]);
                }
                setHasMore(results.length > limit);
            } catch (error) {
                console.error("Error fetching recipes:", error);
            } finally {
                setIsLoading(false);
            }
        }
    }, 300), [query, skip, addRecipeFromUrl]);

    useEffect(() => {
        handleSearch();
    }, [query, skip, handleSearch]);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data === 'REACHED_BOTTOM' && hasMore && !isLoading && !loading) {
                setSkip(prev => prev + limit);
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [hasMore, isLoading, loading, limit]);

    const handleWebSearch = () => {
        window.scrollTo(0, 0);
        navigate(`/search/${query.replace(/ /g, '_')}`);
    };

    const LoadingSkeleton = () => (
        <>
            {[...Array(3)].map((_, i) => (
                <div key={i} style={{ 
                    padding: '15px',
                    border: '1px solid lightgray',
                    borderRadius: '8px',
                    marginBottom: '15px'
                }}>
                    {/* Image Skeleton - reduced height */}
                    <div className={`${styles.skeletonItem}`} style={{
                        width: '100%',
                        height: '120px',
                        marginBottom: '12px',
                        borderRadius: '8px'
                    }} />
                    
                    {/* Title Skeleton */}
                    <div className={`${styles.skeletonItem}`} style={{
                        width: '80%',
                        height: '20px',
                        marginBottom: '8px'
                    }} />
                    
                    {/* Description Skeleton */}
                    <div className={`${styles.skeletonItem}`} style={{
                        width: '60%',
                        height: '16px'
                    }} />
                </div>
            ))}
        </>
    );

    const styles = {
        searchBarContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '1200px',
            boxSizing: 'border-box',
            padding: '0 16px',
            minHeight: '100%',
            overflow: 'visible',
        },
        searchInput: {
            padding: '12px',
            margin: '1em 0',
            width: '100%',
            maxWidth: '600px',
            border: '2px solid #3498db',
            borderRadius: '5px',
            boxSizing: 'border-box',
            fontSize: '1em',
            background: 'white',
        },
        loadingContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100px',
            fontSize: '1.5em',
            color: '#555',
            fontWeight: 'bold',
        },
        spinner: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            marginBottom: '10px',
            width: '250px',
            height: '250px',
        },
        ring: {
            position: 'absolute',
            border: '16px dashed #f3f3f3',
            borderTop: '8px dashed #FFDCCF',
            borderRadius: '50%',
            width: '100%',
            height: '100%',
            animation: 'spin 2s linear infinite, travel 1.5s linear infinite',
        },
        mascotImage: {
            width: '150px',
            zIndex: 1,
            animation: 'bounce 1s infinite',
        },
        resultsContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: 0,
            margin: '20px 0 0 0',
        },
        searchResultsGrid: {
            display: 'grid',
            gap: '1rem',
            gridTemplateColumns: 'repeat(auto-fit, minmax(min(280px, 100%), 1fr))',
            justifyContent: 'center',
            justifyItems: 'center',
            width: '100%',
            height: 'auto',
            overflow: 'visible',
        },
        webSearchButton: {
            padding: '12px 24px',
            backgroundColor: '#3498db',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            fontSize: '1.1rem',
            color: 'white',
            width: '100%',
            marginTop: '20px',
            transition: 'all 0.2s ease',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '16px',
            fontWeight: 500,
        },
    } as const;

    return (
        <>
            {recipeOptions.length > 0 ? (
                <WebRecipeSelector
                    isLoading={isWebSearching}
                    recipeOptions={recipeOptions}
                    onBack={() => setRecipeOptions([])}
                />
            ) : (
                <div style={styles.searchBarContainer}>
                    {!loading && (
                        <input 
                            style={styles.searchInput}
                            value={query} 
                            onChange={e => {
                                setQuery(e.target.value);
                                setSkip(0);
                            }}
                            placeholder="Search for a recipe, or paste in a recipe link" 
                            disabled={loading}
                        />
                    )}
                    {loading && <LoadingSpinner taskId={currentTaskId} />}
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    <div style={styles.resultsContainer}>
                        <div style={styles.searchResultsGrid}>
                            {recipes.map(recipe => (
                                <RecipeSearchResult key={recipe.id} recipe={recipe} />
                            ))}
                            {isLoading && <LoadingSkeleton />}
                            {!isLoading && !hasMore && query && !isWebSearching && (
                                <button 
                                    style={styles.webSearchButton}
                                    onClick={handleWebSearch}
                                >
                                    <FaGlobe />
                                    Search the web for "{query}" recipes
                                </button>
                            )}
                            {isWebSearching && <LoadingSkeleton />}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SearchBar;