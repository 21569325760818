import React from 'react';
import IngredientBlock from './IngredientBlock.tsx';
import RecipeCheckout from './RecipeCheckout.tsx';
import { Recipe } from '../state/recipeStore.ts';

interface RecipeIngredientsProps {
    recipe: Recipe;
}

const RecipeIngredients: React.FC<RecipeIngredientsProps> = ({ recipe }) => {
    const styles = {
        container: {
            padding: '20px',
            fontFamily: 'Arial, sans-serif',
            maxWidth: '100%',
            boxSizing: 'border-box' as const,
        },
        header: {
            color: '#333',
        },
        ingredientList: {
            listStyleType: 'none',
            padding: 0,
            width: '100%',
        },
        ingredientItem: {
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
        },
        imageContainer: {
            marginRight: '10px',
        },
        ingredientImage: {
            width: '50px',
            height: '50px',
            objectFit: 'cover' as const,
            borderRadius: '5px',
        },
        ingredientInfo: {
            display: 'block',
            color: '#555',
        },
        boldText: {
            fontWeight: 'bold',
        },
        errorText: {
            color: 'red',
        },
        loadingText: {
            color: '#888',
        },
    };

    return (
        <div style={styles.container}>
            <RecipeCheckout recipe={recipe}/>
            <h3 style={styles.header}>Shopping List:</h3>
            <ul style={styles.ingredientList}>
                {Object.values(recipe.recipeIngredients).map((ingredient) => {
                    return !!ingredient && (
                        <IngredientBlock
                            key={ingredient.id}
                            recipe_ingredient={ingredient}
                            recipeId={recipe.id}
                        />
                    )
                }
                )}
            </ul>
        </div>
    );
};

export default RecipeIngredients;