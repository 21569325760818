import React, { useState, useEffect } from 'react';
import { useAuthStore } from '../state/authStore.ts';
import { Recipe } from '../state/recipeStore.ts';
import { useSelectionStore } from '../state/selectionStore.ts';
import { useMealPlanStore } from '../state/mealPlanStore.ts';

interface AddToMealPlanButtonProps {
  recipe: Recipe;
}

const AddToMealPlanButton: React.FC<AddToMealPlanButtonProps> = ({ recipe }) => {
  const { prepSelections, addToMealPrepPlan, isLoading, error } = useMealPlanStore();
  const isAlreadyAdded = prepSelections.some(s => s.recipe_id === recipe.id);
  const [added, setAdded] = useState(isAlreadyAdded);

  useEffect(() => {
    setAdded(isAlreadyAdded);
  }, [isAlreadyAdded]);

  const handleAddToMealPlan = async () => {
    if (added) return;
    
    try {
      await addToMealPrepPlan(recipe.id, recipe.servings || 1);
      setAdded(true);
    } catch (error) {
      // Error handling is now done in store
    }
  };

  return (
    <button 
      style={{
        flex: 1,
        backgroundColor: isLoading || added ? '#4CAF50' : '#3d8b40',
        color: 'white',
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        cursor: isLoading || added ? 'not-allowed' : 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        minWidth: '150px',
        height: '40px',
        opacity: (isLoading || added) ? 0.7 : 1,
      }}
      onClick={handleAddToMealPlan}
      disabled={isLoading || added}
    >
      {isLoading ? 'Adding...' : added ? 'Added to Plan' : 'Add to Meal Plan'}
      {added && <span style={{ marginLeft: '8px' }}>&#10004;</span>}
      {error && <div style={{ color: 'red', fontSize: '0.8em', marginTop: '4px' }}>{error}</div>}
    </button>
  );
};

export default AddToMealPlanButton;