import React, { useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

const RecipeDescription = ({ recipe }) => {
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheck = (index) => {
        setCheckedItems(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    function cssUrlEncode(url) {
        // Replace characters like spaces and parentheses that might cause problems in CSS
        return url.replace(/[\s()'"]/g, function(char) {
            return '%' + char.charCodeAt(0).toString(16);
        });
    }

    return (
        <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
            <div style={{ 
                textAlign: 'center', 
                padding: 'clamp(5px, 2vw, 10px)'
            }}>
                <h2 style={{ 
                    fontSize: 'clamp(1.5rem, 4vw, 2rem)',
                    wordBreak: 'break-word'
                }}>
                    {recipe.title} 
                    {recipe.source_url && (
                        <a href={recipe.source_url} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
                            <FaExternalLinkAlt size={16}/>
                        </a>
                    )}
                </h2>
            </div>
            <div 
                style={{
                    width: 'min(90vw, 800px)',
                    maxWidth: '95%',
                    margin: '0 auto',
                    aspectRatio: '1/1',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f5f5f5',
                    backgroundRepeat: 'no-repeat',
                    background: `url(${cssUrlEncode(recipe.picture_link)}) no-repeat center center`
                }}
            ></div>
    
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                padding: 'clamp(5px, 2vw, 10px)',
                borderBottom: '1px solid #ccc',
                width: '100%'
            }}>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <h4 style={{ margin: '5px 0', fontSize: 'clamp(0.9rem, 3vw, 1.2rem)' }}>
                        Preparation Time: {recipe.preparation_minutes} minutes
                    </h4>
                </div>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <h4 style={{ margin: '5px 0', fontSize: 'clamp(0.9rem, 3vw, 1.2rem)' }}>
                        Cooking Time: {recipe.cooking_minutes} minutes
                    </h4>
                </div>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <h4 style={{ margin: '5px 0', fontSize: 'clamp(0.9rem, 3vw, 1.2rem)' }}>
                        Servings: {recipe.servings}
                    </h4>
                </div>
            </div>
    
            <div style={{ 
                padding: 'clamp(5px, 2vw, 10px)',
                maxWidth: '100%'
            }}>
                {recipe.instruction_lines && recipe.instruction_lines.map((line, index) => (
                    <div style={{ 
                        padding: '8px', 
                        margin: '8px 0', 
                        borderRadius: '5px',
                        fontSize: 'clamp(0.9rem, 3vw, 1rem)'
                    }} key={index}>
                        <input 
                            type="checkbox" 
                            id={`instruction-${index}`} 
                            checked={checkedItems[index] || false}
                            onChange={() => handleCheck(index)}
                        />
                        <label 
                            htmlFor={`instruction-${index}`} 
                            style={{ textDecoration: checkedItems[index] ? 'line-through' : 'none' }}
                        >
                            {line}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RecipeDescription;