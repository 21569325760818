import { FC } from 'react';
import { calculateUnitPrice, getUnitType, getQuantityNeeded, getAmountLeftOver, getLeftOverPercentage } from '../utils/PriceCalculation.ts';
import { FeedbackButtons } from './FeedbackButtons.tsx';
import React from 'react';
import { IngredientOption, RecipeIngredient, Unit, Product } from '../state/recipeStore.ts';

interface ProductImageProps {
    imageUrl: string;
    altText: string;
}

interface UnitPriceListProps {
    units: Unit[];
    price: number;
}

interface ProductOptionsListProps {
    recipeIngredient: RecipeIngredient;
    selectedIngredient: IngredientOption;
    selectedProduct: Product | null;
    productOptions: Product[];
    handleProductSelection: (product: Product) => void;
}

const ProductImage: FC<ProductImageProps> = ({ imageUrl, altText }) => (
    <div style={{ width: '50px', height: '50px', overflow: 'hidden', borderRadius: '50%' }}>
        <img src={imageUrl} alt={altText} style={{ width: '100%', height: '100%' }} />
    </div>
);

const UnitPriceList: FC<UnitPriceListProps> = ({ units, price }) => (
    <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
        {units.map((unit, index) => (
            <li key={index} style={{paddingLeft: '10px'}}>
                {calculateUnitPrice(price, unit.quantity, unit.unit)}
            </li>
        ))}
    </ul>
);

export const ProductOptionsList: FC<ProductOptionsListProps> = ({ 
    recipeIngredient, 
    selectedIngredient, 
    selectedProduct, 
    productOptions, 
    handleProductSelection 
}) => (
    <ul style={{ listStyleType: 'none', padding: '0' }}>
        {productOptions.map(option => {
            const isSelected = selectedProduct && selectedProduct.id === option.id;
            const productUnit = getUnitType(recipeIngredient.normalized_unit, option.units)
            const quantityNeeded = getQuantityNeeded(recipeIngredient.normalized_quantity, productUnit.quantity)
            const priceMultiplier = quantityNeeded ? quantityNeeded : 1
            const amountLeftOver = getAmountLeftOver(recipeIngredient, productUnit, quantityNeeded)
            const leftOverPercentage = getLeftOverPercentage(amountLeftOver, productUnit, quantityNeeded);
            const buttonText = isSelected ? 'Selected' : 'Select';

            const buttonStyle = {
                padding: '5px 10px',
                border: 'none',
                borderRadius: '5px',
                cursor: isSelected ? 'not-allowed' : 'pointer',
                backgroundColor: isSelected ? '#c0c0c0' : '#f7f7f7'
            };

            return (
            <li 
                key={option.id} 
                style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '10px', 
                    marginTop: '5px',
                    ...(isSelected && {
                        background: '#fafafa',
                        borderRadius: '10px',
                        padding: '10px',
                        border: '1px solid #ddd',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                    })
                }}
            >
                <button
                    onClick={() => !isSelected && handleProductSelection(option)}
                    style={buttonStyle}
                    disabled={isSelected}
                >
                    {buttonText}
                </button>
                <ProductImage imageUrl={option.image_url} altText={option.title} />
                <div style={{ flexGrow: 1 }}>
                    <span style={{ fontWeight: 'bold', paddingRight: '5px'}}>{quantityNeeded}</span>
                    <span>{option.title}</span>
                    {option.price > 0 ? 
                        ` ($${(Math.round(option.price * priceMultiplier * 100) / 100).toFixed(2)})` : 
                        ' (unavailable)'}
                    {leftOverPercentage !== null && (
                        <span>, {leftOverPercentage}% left over</span>
                    )}
                    <UnitPriceList units={option.units} price={option.price} />
                </div>
                <FeedbackButtons selectedIngredient={selectedIngredient} productId={option.id} />
            </li>
        )})}
    </ul>
);