import React, { useState } from "react";
import { MealPrepSelection } from "../api/types.ts";
import { useDraggable } from "@dnd-kit/core";
import { MdClose, MdEdit } from "react-icons/md";
import { useMealPlanStore } from "../state/mealPlanStore.ts";
import { useRecipeStore } from "../state/recipeStore.ts";
import { useAuthStore } from "../state/authStore.ts";
import { useNavigate } from "react-router-dom";
import { useSelectionStore } from "../state/selectionStore.ts";

const DraggableRecipe = ({
    id,
    children,
    mealData,
    onDelete,
    recipeId
}: {
    id: string,
    children: React.ReactNode,
    mealData: MealPrepSelection,
    onDelete: () => void,
    recipeId: number
}) => {
    const navigate = useNavigate();
    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
        id: id,
        data: { mealData }
    });
    const { getRecipePriceInfo } = useSelectionStore();
    const { totalUsedCost } = getRecipePriceInfo(recipeId);

    const buttonStyles = {
        padding: '6px',
        backgroundColor: '#f8f9fa',
        border: '1px solid #dee2e6',
        borderRadius: '50%',
        cursor: 'pointer',
        width: '28px',
        height: '28px',
        minWidth: '28px',
        minHeight: '28px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.2s ease',
        boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
        margin: 0
    };

    return (
        <div className="recipe-container" style={{
            position: 'relative',
            padding: '16px',
            margin: '4px',
            border: '1px solid #e9ecef',
            borderRadius: '8px',
            backgroundColor: 'white',
            transition: 'box-shadow 0.2s ease',
            boxShadow: isDragging ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0,0,0,0.05)',
            maxWidth: '200px',
            boxSizing: 'border-box',
            zIndex: isDragging ? 1000 : 1,
            opacity: isDragging ? 0.5 : 1,
            touchAction: 'none',
        }}>
            <div 
                ref={setNodeRef}
                {...listeners} 
                {...attributes}
                style={{
                    cursor: 'grab',
                    display: 'flex',
                    alignItems: 'center',
                    touchAction: 'none',
                }}
            >
                {children}
            </div>
            <div style={{
                position: 'absolute',
                top: '2px',
                right: '2px',
                display: 'flex',
                gap: '4px',
                alignItems: 'center'
            }}>
                <button
                    onClick={onDelete}
                    style={{
                        ...buttonStyles,
                        color: '#dc3545',
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = '#fee2e2';
                        e.currentTarget.style.borderColor = '#fecaca';
                        e.currentTarget.style.color = '#dc2626';
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = '#f8f9fa';
                        e.currentTarget.style.borderColor = '#dee2e6';
                        e.currentTarget.style.color = '#dc3545';
                    }}
                >
                    <MdClose size={18} />
                </button>
                <button
                    onClick={() => navigate(`/recipe-view/${recipeId}`)}
                    style={{
                        ...buttonStyles,
                        color: '#6c757d',
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = '#e9ecef';
                        e.currentTarget.style.color = '#495057';
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = '#f8f9fa';
                        e.currentTarget.style.color = '#6c757d';
                    }}
                >
                    <MdEdit size={16} />
                </button>
            </div>
        </div>
    );
};

export const MealPlanTray: React.FC = () => {
    const { isLoading: mealPlanLoading, prepSelections: mealPlan } = useMealPlanStore();
    const { recipes } = useRecipeStore();
    const [deletingIds, setDeletingIds] = useState<number[]>([]);
    const [error, setError] = useState<string | null>(null);
    const { getRecipePriceInfo } = useSelectionStore();

    const handleDelete = (selectionId: number) => {
        const mealPlanStore = useMealPlanStore.getState();
        try {
            setDeletingIds(prev => [...prev, selectionId]);
            mealPlanStore.removeAllSelectionsForRecipe(selectionId);
        } catch (err) {
            console.error('Failed to delete selection:', err);
            setError(err instanceof Error ? err.message : 'Failed to delete selection');
            mealPlanStore.fetchMealPlan();
        } finally {
            setDeletingIds(prev => prev.filter(id => id !== selectionId));
        }
    };

    const allRecipesLoaded = mealPlan.every(plannedMeal => recipes[plannedMeal.recipe_id]);

    const totalCosts = mealPlan.reduce((acc, plannedMeal) => {
        const { totalGroceryCost, totalUsedCost, totalLeftoverCost } = getRecipePriceInfo(plannedMeal.recipe_id);
        return {
            groceryCost: acc.groceryCost + totalGroceryCost,
            usedCost: acc.usedCost + totalUsedCost,
            leftoverCost: acc.leftoverCost + totalLeftoverCost
        };
    }, { groceryCost: 0, usedCost: 0, leftoverCost: 0 });

    return (
        <div style={{ 
            backgroundColor: '#f8f9fa',
            borderTop: '1px solid #dee2e6',
            borderBottom: '1px solid #dee2e6',
            padding: '12px',
            width: '100%',
            boxSizing: 'border-box',
            overflowX: 'hidden'
        }}>
            <div style={{ 
                fontSize: '1rem',
                fontWeight: 'bold',
                marginBottom: '12px',
                paddingLeft: '4px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
            }}>
                Meal Plan
                {mealPlan.length > 0 && !mealPlanLoading && allRecipesLoaded && (
                    <span style={{ color: '#495057', fontSize: '1rem' }}>
                        ${totalCosts.usedCost.toFixed(2)}
                        {totalCosts.leftoverCost > 0 && 
                            <span style={{ color: '#666', fontSize: '0.9rem' }}>
                                (+${totalCosts.leftoverCost.toFixed(2)} leftover)
                            </span>
                        }
                    </span>
                )}
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
                padding: '4px',
                flexWrap: 'wrap',
                width: '100%',
                boxSizing: 'border-box',
            }}>
                {mealPlanLoading || !allRecipesLoaded ? (
                    <span style={{ color: "#666", fontStyle: "italic" }}>Loading...</span>
                ) : mealPlan.length === 0 ? (
                    <span style={{ color: "#666", fontStyle: "italic" }}>No meals selected</span>
                ) : (
                    <div style={{ 
                        display: "flex", 
                        gap: "0.5rem",
                        flexWrap: 'wrap',
                        width: '100%',
                        boxSizing: 'border-box',
                        justifyContent: 'center',
                    }}>
                        {mealPlan.map((plannedMeal: MealPrepSelection) => {
                            const recipe = recipes[plannedMeal.recipe_id];
                            if (!recipe) return null;

                            return (
                                <DraggableRecipe
                                    key={plannedMeal.recipe_id}
                                    id={`recipe-${plannedMeal.recipe_id}`}
                                    mealData={plannedMeal}
                                    onDelete={() => handleDelete(plannedMeal.recipe_id)}
                                    recipeId={plannedMeal.recipe_id}
                                >
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "0.5rem"
                                    }}>
                                        <img
                                            src={recipe.picture_link}
                                            alt={recipe.title}
                                            style={{
                                                width: "48px",
                                                height: "48px",
                                                objectFit: "cover",
                                                borderRadius: "6px"
                                            }}
                                        />
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}>
                                            <span style={{
                                                fontSize: "1rem",
                                                width: "120px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                fontWeight: "500",
                                                color: "#495057"
                                            }}>
                                                {recipe.title}
                                            </span>
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "8px",
                                                fontSize: "0.875rem",
                                                color: "#666"
                                            }}>
                                                <span style={{
                                                    fontWeight: "500",
                                                    color: "#495057",
                                                    backgroundColor: "#f8f9fa",
                                                    padding: "2px 6px",
                                                    borderRadius: "4px",
                                                    border: "1px solid #dee2e6"
                                                }}>
                                                    ${getRecipePriceInfo(recipe.id).totalUsedCost.toFixed(2)}
                                                </span>
                                                <span>{plannedMeal.servings} servings</span>
                                            </div>
                                        </div>
                                    </div>
                                </DraggableRecipe>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};