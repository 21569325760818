import React, { useState, useRef } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { useRecipeStore } from '../state/recipeStore.ts';

export const RecipePreviewModal: React.FC<RecipePreviewModalProps> = ({ url, onClose }) => {
  const { addRecipeFromUrl } = useRecipeStore();
  const [hasLoadError, setHasLoadError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleIframeError = () => {
    setIsLoading(false);
    setHasLoadError(true);
  };

  const handleIframeLoad = () => {
    // If we get here, the frame loaded successfully
    setIsLoading(false);
    setHasLoadError(false);
  };

  const handleAddRecipe = () => {
    addRecipeFromUrl(url);
    onClose();
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1200,
    }} onClick={onClose}>
      <div style={{
        position: 'relative',
        background: 'white',
        width: '90%',
        maxWidth: '800px',
        height: '90vh',
        borderRadius: '4px',
        padding: '40px 20px 20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      }} onClick={e => e.stopPropagation()}>
        <div style={{
          position: 'absolute',
          top: '32px',
          left: '10px',
          display: 'flex',
          gap: '10px',
        }}>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              padding: '6px 12px',
              background: '#0066cc',
              color: 'white',
              borderRadius: '4px',
              textDecoration: 'none',
              fontSize: '14px',
              lineHeight: '1.5',
              cursor: 'pointer',
              minHeight: '28px',
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            View in New Tab
            <FiExternalLink size={16} />
          </a>
          <button
            onClick={handleAddRecipe}
            style={{
              padding: '6px 12px',
              background: '#28a745',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              fontSize: '14px',
              lineHeight: '1.5',
              cursor: 'pointer',
              minHeight: '28px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Add to Meal Plan
          </button>
        </div>
        <button 
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '24px',
            cursor: 'pointer',
            width: '30px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            ':hover': {
              background: 'rgba(0, 0, 0, 0.05)',
            }
          }}
        >
          ×
        </button>
        
        {hasLoadError ? (
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <p>This site cannot be displayed in preview mode.</p>
            <a 
              href={url} 
              target="_blank" 
              rel="noopener noreferrer"
              style={{
                color: '#0066cc',
                textDecoration: 'underline',
                marginTop: '10px',
                display: 'inline-block'
              }}
            >
              Open recipe in new tab
            </a>
          </div>
        ) : (
          <iframe
            ref={iframeRef}
            src={url}
            onError={handleIframeError}
            onLoad={handleIframeLoad}
            sandbox="allow-same-origin allow-scripts"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              borderRadius: '4px',
              top: '64px'
            }}
            title="Recipe Preview"
          />
        )}
      </div>
    </div>
  );
};

export default RecipePreviewModal;