import React, { useEffect } from 'react';
import './Animations.css';
import AddToCartButton from './AddToCartButton.tsx';
import OAuthButton from './OAuthButton.tsx';
import AddToMealPlanButton from './AddToMealPlanButton.tsx';
import { getProductPriceInfo, getProductQuantity } from '../utils/PriceCalculation.ts';
import { RecipeIngredient, useRecipeStore } from '../state/recipeStore.ts';
import { useSelectionStore } from '../state/selectionStore.ts';
import { useAuthStore } from '../state/authStore.ts';

interface PriceInfo {
    priceOfQuantityUsed: number;
    priceOfQuantityRemaining: number;
}

interface RecipePriceDisplayProps {
    recipeId: number;
}

const skeletonAnimation = {
    animation: 'shimmer 1.5s infinite',
    background: 'linear-gradient(90deg, rgba(211, 211, 211, 0.2), rgba(211, 211, 211, 0.4), rgba(211, 211, 211, 0.2))',
    backgroundSize: '200% 100%',
} as React.CSSProperties;

const RecipePriceDisplay: React.FC<RecipePriceDisplayProps> = ({ recipeId }) => {
    const krogerAuthToken = useAuthStore(state => state.krogerToken);
    const { recipes, fetchRecipe, loadingStates } = useRecipeStore();
    const { 
        isSelectionsLoading,
        getRecipePriceInfo
    } = useSelectionStore();

    const recipe = recipes[recipeId];
    const selectionsLoading = isSelectionsLoading(recipeId);
    const recipeLoading = !recipe;
    
    // Check if any ingredients are loading
    const ingredientsLoading = recipe?.recipeIngredients ? 
        Object.values(recipe.recipeIngredients).some(ingredient => 
            loadingStates[recipeId]?.[ingredient.id]?.ingredients ||
            Object.values(loadingStates[recipeId]?.[ingredient.id]?.products || {}).some(loading => loading)
        ) : false;

    const isLoading = selectionsLoading || recipeLoading || ingredientsLoading;

    useEffect(() => {
        if (!recipe) {
            fetchRecipe(recipeId);
        }
    }, [recipeId, recipe, fetchRecipe]);

    // Get price info directly from the store
    const { totalGroceryCost, totalUsedCost, totalLeftoverCost } = getRecipePriceInfo(recipeId);

    if (isLoading) {
        return (
            <div style={{
                background: '#fafafa',
                borderRadius: '10px',
                padding: '10px',
                textAlign: 'right',
                marginLeft: '10px',
                fontFamily: 'Arial, sans-serif',
                border: '1px solid #ddd',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '100%',
                overflow: 'auto'
            }}>
                <div style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                    textAlign: 'center',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word'
                }}>
                    <div style={{
                        width: '80%',
                        maxWidth: '200px',
                        height: '28px',
                        borderRadius: '14px',
                        margin: '0 auto',
                        ...skeletonAnimation,
                    }} />
                </div>
                <div style={{
                    fontSize: '18px',
                    textAlign: 'center',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word'
                }}>
                    <div style={{
                        width: '90%',
                        maxWidth: '300px',
                        height: '48px',
                        borderRadius: '12px',
                        margin: '0 auto',
                        ...skeletonAnimation,
                    }} />
                </div>
                <div style={{
                    display: 'flex',
                    gap: '8px',
                    marginTop: '8px',
                    width: '100%',
                    flexDirection: 'column'
                }}>
                    <div style={{
                        flex: 1,
                        height: '40px',
                        borderRadius: '20px',
                        ...skeletonAnimation,
                    }} />
                    <div style={{
                        flex: 1,
                        height: '40px',
                        borderRadius: '20px',
                        ...skeletonAnimation,
                    }} />
                </div>
            </div>
        );
    }

    if (!recipe) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{
            background: '#fafafa',
            borderRadius: '10px',
            padding: '10px',
            textAlign: 'right',
            marginLeft: '10px',
            fontFamily: 'Arial, sans-serif',
            border: '1px solid #ddd',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
            overflow: 'auto'
        }}>
            <div style={{
                fontSize: '20px',
                fontWeight: 'bold',
                marginBottom: '5px',
                textAlign: 'center',
                wordWrap: 'break-word',
                overflowWrap: 'break-word'
            }}>
                Recipe Cost: ${totalUsedCost.toFixed(2)}
            </div>
            <div style={{
                fontSize: '18px',
                textAlign: 'center',
                wordWrap: 'break-word',
                overflowWrap: 'break-word'
            }}>
                <div>
                    Grocery Cost: ${totalGroceryCost.toFixed(2)}
                    <br />
                    Leftover: ${totalLeftoverCost.toFixed(2)}
                </div>
            </div>
            {recipe && (
                <div style={{
                    display: 'flex',
                    gap: '8px',
                    marginTop: '8px',
                    width: '100%',
                    flexDirection: 'column'
                }}>
                    {krogerAuthToken ? (
                        <AddToCartButton recipe={recipe} modality="PICKUP" />
                    ) : (
                        <OAuthButton />
                    )}
                    <AddToMealPlanButton recipe={recipe} />
                </div>
            )}
        </div>
    );
};

export default RecipePriceDisplay;