import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase.config.ts';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import React from 'react';

export const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleGoogleLogin = async () => {
    setIsLoading(true);
    setError('');
    const provider = new GoogleAuthProvider();
    
    try {
      const result = await signInWithPopup(auth, provider);
      if (result.user) {
        navigate('/');
      }
    } catch (error) {
      setError('Failed to sign in. Please try again.');
      console.error('Error signing in:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 'calc(100vh - 64px)', // Assuming header is 64px
      backgroundColor: '#f5f5f5',
      padding: '20px' // Add padding to prevent content touching edges
    }}>
      <div style={{
        padding: '40px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        width: '100%',
        maxWidth: '400px' // Add max-width for better responsiveness
      }}>
        <img 
          src="/logo512.png"
          alt="Kitchen Copilot Logo"
          style={{
            width: '180px', // Increased from 120px
            height: '180px', // Increased from 120px
            marginBottom: '24px'
          }}
        />
        <h1 style={{ marginBottom: '24px', color: '#333' }}>Welcome to Kitchen Copilot</h1>
        <p style={{ marginBottom: '24px', color: '#666' }}>Sign in to access your recipes and meal plans</p>
        
        <button 
          onClick={handleGoogleLogin}
          disabled={isLoading}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            width: '100%',
            padding: '12px 24px',
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
            cursor: 'pointer',
            transition: 'background-color 0.2s',
            ':hover': {
              backgroundColor: '#f8f8f8'
            }
          }}
        >
          <img 
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google"
            style={{ width: '18px', height: '18px' }}
          />
          {isLoading ? 'Signing in...' : 'Sign in with Google'}
        </button>
        
        {error && (
          <p style={{ 
            color: '#d32f2f',
            marginTop: '16px',
            fontSize: '14px'
          }}>
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default Login;