import axios from 'axios';
import API_URL from '../config';
import { IngredientOptionResponse, ProductResponse, CartItem, RecipeHeaderResponse, TaskStatusResponse, TaskResponse, RecipeResponse, MealPrepSelection, RecipeIngredientSelection, MealServingSelection } from './types';
import { Recipe, RecipeIngredient } from '../state/recipeStore';
import { useAuthStore } from '../state/authStore.ts';

export const getClosestIngredients = async (recipeId: number, recipeIngredientId: number) => {
    try {
        const { data } = await axios.get(`${API_URL}/recipe-ingredient/${recipeIngredientId}/closest-ingredients`);
        return data.ingredients as IngredientOptionResponse[];
    } catch (err) {
        console.error('Failed to fetch closest ingredients:', err);
        return [];
    }
}

export const getServingSelections = async (
  authToken: string
): Promise<MealServingSelection[]> => {
  const response = await fetch(
    `${API_URL}/meal-plan/servings`,
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': `bearer ${authToken}`
      }
    }
  );
  if (!response.ok) throw new Error('Failed to fetch serving selections');
  return response.json();
};

// Create a new serving selection
export const createServingSelection = async (
  selection: MealServingSelection,
  authToken: string
): Promise<MealServingSelection> => {
  const response = await fetch(
    `${API_URL}/meal-plan/servings`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `bearer ${authToken}`
      },
      body: JSON.stringify(selection)
    }
  );
  if (!response.ok) throw new Error('Failed to create serving selection');
  return response.json();
};

// Delete a serving selection
export const deleteServingSelection = async (
  selectionId: number
): Promise<void> => {
  const authToken = await useAuthStore.getState().getValidFirebaseToken();
  const response = await fetch(
    `${API_URL}/meal-plan/servings/${selectionId}`,
    {
      method: 'DELETE',
      headers: {
        'Authorization': `bearer ${authToken}`
      }
    }
  );
  if (!response.ok) throw new Error('Failed to delete serving selection');
};

export const getMealPrepSelections = async (
  authToken: string
): Promise<MealPrepSelection[]> => {
  const response = await fetch(
    `${API_URL}/meal-plan/selections`,
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': `bearer ${authToken}`
      }
    }
  );
  if (response.status === 401) {
    console.log('Not logged in to fetch selections');
    return [];
  }
  if (!response.ok) throw new Error('Failed to fetch meal plan selections');
  return response.json();
};

export const createMealPrepSelection = async (
  selection: Pick<MealPrepSelection, 'recipe_id' | 'servings'>,
  authToken: string
): Promise<MealPrepSelection> => {
  const response = await fetch(
    `${API_URL}/meal-plan/selections`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json', 
        'Authorization': `bearer ${authToken}`
      },
      body: JSON.stringify(selection)
    }
  );
  if (!response.ok) throw new Error('Failed to create meal plan selection');
  return response.json();
};

export const deleteMealPlanSelection = async (
  selectionId: number
): Promise<void> => {
  const authToken = await useAuthStore.getState().getValidFirebaseToken();
  const response = await fetch(
    `${API_URL}/meal-plan/selections/${selectionId}`,
    {
      method: 'DELETE',
      headers: {
        'Authorization': `bearer ${authToken}`
      }
    }
  );
  if (!response.ok) throw new Error('Failed to delete meal plan selection');
};

export const getProductOptions = async (ingredientId: number, count: number = 10) => {
    try {
        const { data } = await axios.get(`${API_URL}/product-options/${ingredientId}?top_n=${count}`);
        return data.products as ProductResponse[];
    } catch (err) {
        console.error('Failed to fetch product options:', err);
        return [];
    }
}

export const addItemsToCart = async (items: CartItem[], token: string, maxRetries = 3) => {
  if (!token) throw new Error('Missing authentication token');
  if (!items?.length) throw new Error('No items provided');

  const krogerItems = items.map(item => ({
    upc: item.upc,
    quantity: item.quantity,
    modality: "PICKUP"
  }));

  let attempt = 0;
  while (attempt < maxRetries) {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 10000);

      // Use our proxy endpoint instead of direct Kroger API call
      const response = await fetch(`${API_URL}/proxy/cart/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Backend will forward this
        },
        body: JSON.stringify({ items: krogerItems }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Cart API Error:', {
          status: response.status,
          statusText: response.statusText,
          body: errorText,
          attempt: attempt + 1
        });
        
        if (response.status === 429 || response.status >= 500) {
          attempt++;
          await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
          continue;
        }
        
        throw new Error(`Failed to add items: ${response.status} - ${errorText}`);
      }

      return true;

    } catch (error) {
      if (error.name === 'AbortError') {
        console.error('Request timed out');
        attempt++;
        continue;
      }
      
      if (attempt === maxRetries - 1) {
        throw error;
      }
      
      attempt++;
      await new Promise(resolve => setTimeout(resolve, 1000 * attempt));
    }
  }
  
  throw new Error('Maximum retry attempts reached');
}

export const refreshKrogerToken = async (refreshToken: string): Promise<{
  accessToken: string;
  refreshToken: string;
  expires_in: number;
  token_type: string;
}> => {
  try {
    const response = await fetch(`${API_URL}/proxy/token/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refreshToken })
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to refresh token: ${response.status} - ${errorText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Token refresh failed:', error);
    throw error;
  }
};

export const searchRecipes = async (query: string, skip: number, limit: number): Promise<RecipeHeaderResponse[]> => {
    try {
        const { data } = await axios.get(`${API_URL}/search/?query=${query}&skip=${skip}&limit=${limit}`);
        return data;
    } catch (err) {
        console.error('Failed to fetch recipes:', err);
        return [];
    }
}

export const addRecipeFromUrl = async (url: string): Promise<TaskResponse> => {
  try {
    const response = await axios.post(`${API_URL}/add_recipe/`, { url });
    return response.data;
  } catch (err) {
    console.error('Failed to add recipe:', err);
    throw err;
  }
};

export const getTaskStatus = async (taskId: string): Promise<TaskStatusResponse> => {
  try {
    const response = await axios.get(`${API_URL}/task_status/${taskId}`);
    return response.data;
  } catch (err) {
    console.error('Failed to get task status:', err);
    throw err;
  }
};

export const apiFetchRecipe = async (recipeId: number): Promise<Recipe> => {
  const response = await axios.get<RecipeResponse>(`${API_URL}/recipe/${recipeId}`) as { data: RecipeResponse };
  
  // Transform RecipeResponse ingredients into Recipe recipeIngredients format
  const recipeIngredients: Record<number, RecipeIngredient> = {};
  Object.entries(response.data.ingredients).forEach(([id, ingredient]) => {
    recipeIngredients[ingredient.id] = {
      id: ingredient.id,
      recipe_id: response.data.id,
      ingredient_id: parseInt(ingredient.ingredient_id),
      raw_text: ingredient.raw_text,
      quantity: ingredient.quantity,
      unit: ingredient.unit,
      normalized_unit: ingredient.normalized_unit,
      preparation: ingredient.preparation,
      ingredientOptions: [],
      ingredient_name: ingredient.ingredient_name,
      normalized_quantity: ingredient.normalized_quantity,
    };
  });

  // Return transformed Recipe object
  return {
    id: response.data.id,
    recipeIngredients,
    title: response.data.title,
    instruction_lines: response.data.instruction_lines,
    picture_link: response.data.picture_link,
    preparation_minutes: response.data.preparation_minutes,
    cooking_minutes: response.data.cooking_minutes,
    servings: response.data.servings,
    source_url: response.data.source_url
  };
};

export const apiReprocessRecipe = async (recipeId: number, url: string): Promise<TaskResponse> => {
  const response = await axios.post<TaskResponse>(
    `${API_URL}/reprocess_recipe/`, 
    { recipe_id: recipeId, url }
  );
  return response.data;
};

export const getRecipeSelections = async (
  recipeId: number, 
  authToken: string
): Promise<RecipeIngredientSelection[]> => {
  const response = await fetch(
    `${API_URL}/recipe/${recipeId}/selections`,
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': `bearer ${authToken}`
      }
    }
  );
  if (response.status === 401) {
    console.log('Not logged in to fetch selections');
    return [];
  }
  if (!response.ok) {
    if (response.status === 404) {
      return [];
    }
    throw new Error('Failed to fetch selections');
  }
  return response.json();
};

export const updateRecipeSelections = async (
  recipeId: number,
  selections: RecipeIngredientSelection[],
  authToken: string
): Promise<void> => {
  const response = await fetch(
    `${API_URL}/recipe/${recipeId}/selections`, 
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `bearer ${authToken}`
      },
      body: JSON.stringify(selections)
    }
  );
  if (!response.ok) throw new Error('Failed to update selections');
};
