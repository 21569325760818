import React, { useState, useEffect } from "react";
import { searchRecipes } from '../api/api.ts';
import { RecipeHeaderResponse } from '../api/types.ts';
import InspirationSearch from '../InspirationSearch/InspirationSearch.tsx';
import { useMealPlanStore } from '../state/mealPlanStore.ts';
import { useRecipeStore } from '../state/recipeStore.ts';
import { RecipeSelectionBanner } from "../components/RecipeSelectionBanner.tsx";
import SearchBar from "../components/SearchBar.tsx";
import { useNavigate } from 'react-router-dom';
import { NavToggleHeader } from "../components/NavToggleHeader.tsx";

const INITIAL_FETCH_LIMIT = 30;
const FOOTER_HEIGHT = '160px'; // Define footer height constant

const MealPlanner: React.FC = () => {
  const navigate = useNavigate();
  const [libraryRecipes, setLibraryRecipes] = useState<RecipeHeaderResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [mode, setMode] = useState<'search' | 'copilot' | null>('search');
  
  const { 
    prepSelections: mealPlan,
    fetchMealPlan,
    isLoading: mealPlanLoading
  } = useMealPlanStore();

  useEffect(() => {
    fetchMealPlan();
  }, []);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        setLoading(true);
        setError(null);
        const recipes = await searchRecipes("", 0, INITIAL_FETCH_LIMIT);
        setLibraryRecipes(recipes);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch recipes');
      } finally {
        setLoading(false);
      }
    };

    fetchRecipes();
  }, []);

  useEffect(() => {
    const loadRecipeDetails = async () => {
      const recipeStore = useRecipeStore.getState();
      const uniqueRecipeIds = [...new Set(mealPlan.map(item => item.recipe_id))];
      
      for (const recipeId of uniqueRecipeIds) {
        if (!recipeStore.recipes[recipeId]) {
          await recipeStore.fetchRecipe(recipeId);
        }
      }
    };

    if (mealPlan.length > 0) {
      loadRecipeDetails();
    }
  }, [mealPlan]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;
      
      if ((documentHeight <= window.innerHeight || scrollPosition >= documentHeight - 100) 
          && (mode === 'copilot' || mode === 'search')) {
        window.postMessage('REACHED_BOTTOM', '*');
        // Handle scroll in 1 second
        setTimeout(() => {
          handleScroll();
        }, 1000);
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [mode]);

  const handleModeSelect = (selectedMode: 'search' | 'copilot') => {
    if (mode !== selectedMode) {
      setMode(selectedMode);
      if (selectedMode === 'copilot') {
        navigate('/inspiration');
      }
    }
  };

  return (
    <>
      <NavToggleHeader mode={mode!}/>
      
      <div style={{ 
        width: '100%',
        maxWidth: '1200px',
        minWidth: '320px',
        margin: '0 auto',
        padding: '0 clamp(12px, 3vw, 20px)',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
      }}>
        {error && <div className="error">{error}</div>}
        
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column',
          paddingBottom: FOOTER_HEIGHT,
          marginBottom: 0,
          marginTop: 0,
          paddingTop: 0,
          width: '100%'
        }}>
          {mode === 'search' && (
            <div style={{ 
              flex: 2,
              width: '100%'
            }}>
              <SearchBar />
            </div>
          )}
        </div>

        <RecipeSelectionBanner />
      </div>
    </>
  );
};

export default MealPlanner;